(function () {
    function r(e, n, t) {
        function o(i, f) {
            if (!n[i]) {
                if (!e[i]) {
                    var c = "function" == typeof require && require;if (!f && c) return c(i, !0);if (u) return u(i, !0);var a = new Error("Cannot find module '" + i + "'");throw a.code = "MODULE_NOT_FOUND", a;
                }var p = n[i] = { exports: {} };e[i][0].call(p.exports, function (r) {
                    var n = e[i][1][r];return o(n || r);
                }, p, p.exports, r, e, n, t);
            }return n[i].exports;
        }for (var u = "function" == typeof require && require, i = 0; i < t.length; i++) {
            o(t[i]);
        }return o;
    }return r;
})()({ 1: [function (require, module, exports) {
        var translate = bestCountryPriceTranslates;
        var links = bestCountryPriceLinks;

        var bcParams = bestCountryPricesWidgetMenuParams;

        $(document).ready(function () {
            var $itemsContainer = $('.w-prices__items');

            function loadImages(onlyVisibleNow) {
                var itemsSelector = '.country-item.lazy-item' + (onlyVisibleNow ? ':not(.hidden-on-preview)' : '');
                var $items = $itemsContainer.find(itemsSelector);
                $items.removeClass('lazy-item');
                $items.each(function (i, element) {
                    window.brxLazyImage.loadChildren(element);
                });
            }

            if ($itemsContainer.length) {
                if (window.utils.isAnyPartOfElementInViewport($itemsContainer)) {
                    loadImages(true);
                } else {
                    var onScroll = window.utils.throttle(function () {
                        if (window.utils.isAnyPartOfElementInViewport($itemsContainer)) {
                            loadImages(true);
                            $(window).off('scroll', onScroll);
                        }
                    }, 100);
                    $(window).scroll(onScroll);
                }
            }

            var $mainCountryItems = $('.w-prices .item-best-prices .country-item');
            if ($mainCountryItems.length) {
                $.each($mainCountryItems, function () {
                    var t = $(this);
                    var dc = t.closest('.item-best-prices').data('dc-id');
                    if (!t.find('a').length) {
                        var href = links.tours + '/' + bcParams.countries[t.data('country-id')] + (dc ? '-from-' + bcParams.departCities[dc] : '');
                        t.html('<a href="' + href + '" class="country-link">' + t.html() + '</a>');
                    }
                });
            }

            var $menuCountryItems = $('.w-prices .tours-country-menu .replace_target');
            if ($menuCountryItems.length) {
                $.each($menuCountryItems, function () {
                    var $self = $(this);
                    var data = $self.data();
                    var url = links.tours + '/' + bcParams.countries[data.countryId];
                    if (data.dc && bcParams.departCities.hasOwnProperty(data.dc)) {
                        url += '-from-' + bcParams.departCities[data.dc];
                    }
                    $self.replaceWith('<a href="' + url + '">' + $self.html() + '</a>');
                });
            }

            function onCityChange(value, changeGlobalCity) {
                var cities = window.cities;

                Object.values(cities).forEach(function (city) {
                    if (value === city.find_id) {
                        if (changeGlobalCity) {
                            window.setCity(city.name, false, true, function () {});
                        }
                    }

                    showContent(value);
                });
            }

            function showContent(cityId) {
                var $item = $('.item-best-prices[data-dc-id="' + cityId + '"]');

                if ($item.length) {
                    $('.item-best-prices.active').removeClass('active');
                    $item.addClass('active');
                }
            }

            $('#s_w-country-price__city').on('change', function () {
                var city_id = parseInt($(this).val(), 10);
                var changeGlobalCity = parseInt($(this).data('changeGlobalCity'), 10);

                onCityChange(city_id, changeGlobalCity);
                $(this).data('changeGlobalCity', 1);
            });

            $('body').on('changeGlobalCity', function (e, data) {

                $('#s_w-country-price__city').val(data.cityId);
                $('#s_w-country-price__city').data('changeGlobalCity', 0);
                $('#s_w-country-price__city').trigger('change');
            }).on('click', '.country-link', function (e) {
                var cityId = $(e.currentTarget).closest('.item-best-prices').data('dcId');
                var countryId = $(e.currentTarget).closest('.country-item').data('countryId');
                window.brxTracking.sendAnalyticsEvent('click_sale_main_page', {
                    cityId: cityId,
                    countryId: countryId
                });
            });

            $('.w_prices_more_show').on('click', function (e) {
                var $this = $(this);
                var $hideCountries = $('<span class="more_countries hide-countries">Скрыть</span>');
                var parent = $this.closest('.country-items-wrapper');
                var itemsSelector = $(parent).find('.country-item:not(.sm-country-item)');

                if ($this.find($('.hide-countries')).length) {
                    $(parent).find('.visible-country-item').removeClass('visible-country-item');
                    $this.find($('.hide-countries')).remove();
                    $this.find('.sm-more-countries').removeClass('hidden');
                } else {
                    itemsSelector.addClass('visible-country-item');
                    $this.find('.sm-more-countries').addClass('hidden');
                    loadImages();
                    $this.append($hideCountries);
                }
            });
        });
    }, {}] }, {}, [1]);